import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"
export function Footer() {

return(
    <div class="footer">
    <h1>Let's Get in Touch</h1>
    <p>Please use the contact form on top to reach me or feel free to leave me a message on gayathridinesh006@gmail.com</p>
    
    </ div>
);

}